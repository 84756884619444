<template>
  <p
    class="service-detail-description"
    v-html="getFormattedDescription(detail.description)"
    v-html-reactive-links
  />
</template>

<script>
export default {
  props: ['detail'],
  methods: {
    getFormattedDescription(text) {
      return text
        .replace(/\r\n/g, '<br>')
        .replace(/\r\n/g, '<br>')
        .replace(/\n/g, '<br>')
        .replace(/\r/g, '<br>')
    },
  },
}
</script>

<style lang="scss">
.service-detail-description {
  margin-top: 15px;
  padding: 0 15px;
  line-height: 20px;
  font-weight: 300;
  color: black;
}
</style>
