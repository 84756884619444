<template>
  <div class="service-detail-images">
    <b-carousel
      v-if="detail.images.length > 1"
      class="images"
      controls
      indicators
      v-model="activeSlide"
      @click.native="showImages"
    >
      <b-carousel-slide
        style="height: 200px;"
        v-for="(image, key) in detail.images"
        :key="key"
        :img-src="image.url"
      />
    </b-carousel>
    <p
      class="images-caption"
      v-if="detail.images.length > 1 && currentSlideDescription"
    >
      {{ currentSlideDescription }}
    </p>
    <div
      class="images single"
      v-if="detail.images.length == 1"
      :style="{ backgroundImage: `url(${detail.images[0].url})` }"
      @click="showImages"
    ></div>
  </div>
</template>

<script>
export default {
  props: ['detail'],
  data() {
    return {
      activeSlide: 0,
    }
  },
  methods: {
    showImages() {
      const images = this.detail.images.map(img => ({
        src: img.url,
        alt: img.description,
      }))
      this.$events.emit('show-images', images, this.activeSlide)
    },
  },
  computed: {
    currentSlideDescription() {
      if (
        !this.detail.images ||
        this.detail.images.length == 0 ||
        !this.detail.images[this.activeSlide]
      )
        return ''
      return this.detail.images[this.activeSlide].description
    },
  },
}
</script>

<style lang="scss">
.service-detail-images {
  .images {
    width: 100%;
    height: 220px;
    background-position: center;
    background-size: cover;

    &.single {
      margin-bottom: 15px;
    }

    .carousel-inner {
      .carousel-item {
        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
          cursor: pointer;
        }
      }
    }
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
    }
  }
  .images-caption {
    text-align: center;
    color: #7a7a7a;
    font-size: 18px;
    padding: 0 15px;
    padding-bottom: 5px;
  }
}
</style>
