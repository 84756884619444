<template>
  <div id="room-service" ref="main">
    <Loading v-if="loading" />
    <TopMenu
      :title="service.title"
      :customBack="
        () => {
          $router.push('/hotel/' + service.propertyId)
        }
      "
    />
    <div class="description">{{ details.offer.description }}</div>
    <div
      class="child"
      v-for="child in childOffers"
      :key="child.id"
      @click="makeOrder(child)"
    >
      <span class="title">{{ child.title }}</span>
      <div class="info">
        <span class="price"
          >{{ child.priceValue }} {{ child.priceCurrency }}</span
        >
      </div>
    </div>
    <LegalInfo :text="details.offer.legalInfo" />
  </div>
</template>

<script>
import TopMenu from '@/components/TopMenu'
import LegalInfo from '@/components/LegalInfo'

export default {
  props: ['id'],
  components: { TopMenu, LegalInfo },
  data() {
    return {
      service: {},
      details: {
        title: '',
        offer: {
          description: '',
        },
      },
      childOffers: [],
      loading: true,
    }
  },
  mounted() {
    this.$api.get(`services/${this.id}`).then(res => {
      this.service = res.data.data
      Object.keys(this.service.serviceDetails).forEach(key => {
        const detail = this.service.serviceDetails[key]
        const type = detail['@type'] ? detail['@type'].toLowerCase() : key

        let content = detail
        if (detail[type]) content = detail[type]
        if (detail['value']) content = detail['value']

        this.details[type] = content
      })

      this.$api.get(`offers/${this.details.offer.id}`).then(res => {
        this.childOffers = res.data.data.childOffers
      })

      this.loading = false
    })
  },
  methods: {
    makeOrder(child) {
      this.$api
        .post(`user/orders`, {
          action: 'CREATE',
          serviceId: child.serviceId,
          availablePaymentMethods: true,
          orderItemRequests: [
            {
              action: 'ADD',
              offerId: child.id,
              attributeValues: [],
            },
          ],
        })
        .then(res => {
          this.$router.push(`/order?orderId=${res.data.data.orderId}`)
        })
        .catch(err => {
          if (!this.$store.isLoginError(err))
            this.$events.emit(
              'show-alert',
              this.$t('errors.error'),
              err.response.data.message
            )
          else this.$events.once('authorized', () => this.makeOrder(child))
        })
    },
  },
}
</script>

<style lang="scss">
#room-service {
  .description {
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    font-size: 18px;
  }
  .child {
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    width: 90%;
    margin-bottom: 15px;
    text-align: center;
    color: rgb(58, 58, 58);

    border-bottom: #ccc 1px solid;
    transition: 0.15s all;

    display: flex;
    justify-content: space-between;

    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }

    .title {
      flex: 1;
      text-align: left;
    }

    .info {
      .price {
        margin-right: 5px;
      }
      .icon {
      }
    }
  }
}
</style>
