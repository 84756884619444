<template>
  <component
    :is="components[type]"
    :detail="detail"
    :offer="offer"
    :order="order"
    :offerProps="offerProps"
  />
</template>

<script>
import Title from '@/components/ServiceDetails/Title'
import Description from '@/components/ServiceDetails/Description'
import Chips from '@/components/ServiceDetails/Chips'
import Images from '@/components/ServiceDetails/Images'
import Offer from '@/components/ServiceDetails/Offer'

export default {
  props: ['detail', 'offer', 'offerProps', 'order'],
  components: {
    Title,
    Description,
    Chips,
    Images,
    Offer,
  },
  data() {
    return {
      components: {
        title: Title,
        description: Description,
        chips: Chips,
        images: Images,
        offer: Offer,
      },
    }
  },
  computed: {
    type() {
      return this.detail['@type'].toLowerCase()
    },
  },
}
</script>
