<template>
  <div class="age-restriction-window" :style="{width: fixedWidth + 'px'}">
    <TopMenu
      :title="service.title"
      :customBack="() => {$router.push('/hotel/' + service.propertyId)}"
      ref="topMenu"
    />
    
    <div class="window">
      <div class="title">{{$t('ageRestriction.title')}}</div>
      <div class="subtitle">{{$t('ageRestriction.subtitle')}}</div>

      <div class="buttons">
        <b-button variant="outline-danger" @click="no">{{ $t('order.no') }}</b-button>
        <b-button variant="outline-success" @click="yes">{{ $t('order.yes') }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import TopMenu from './TopMenu.vue'
export default {
  props: ['service'],
  components: { TopMenu },
  data() {
    return {
      fixedWidth: this.$full ? this.routerWidth : window.innerWidth
    }
  },
  mounted() {
    let interval = setInterval(() => {
      if(this.routerWidth != 0) {
        this.updateFixedWidth()
        clearInterval(interval)
      }
    }, 1)
  },
  methods: {
    no() {
      this.$refs.topMenu.back()
    },
    yes() {
      localStorage.agreeAgeRestriction = true
      this.$emit('agreed')
    },
    updateFixedWidth() {
      this.fixedWidth = this.$full ? this.routerWidth : window.innerWidth
    },
    _resize() {
      this.updateFixedWidth()
    }
  },
  watch: {
    routerWidth() {
      this.updateFixedWidth()
    }
  },
  computed: {
    routerWidth() {
      return this.$store.routerWidth
    },
  },
}
</script>

<style lang="scss">
.age-restriction-window {
  position: fixed;
  z-index: 15;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .5);
  backdrop-filter: blur(5px);

  >.window {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 4px;
    padding: 15px;

    >.title {
      font-size: 18px;
      margin-bottom: 15px;
      text-align: center;
    }
    >.subtitle {
      color: #777;
      text-align: center;
      margin-bottom: 15px;
    }
    >.buttons {
      display: flex;
      justify-content: center;
      align-items: center;

      >*:nth-child(1) {
        margin-right: 10px;
      }
    }
  }
}
</style>