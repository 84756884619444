<template>
  <p class="service-detail-title">{{ detail.value }}</p>
</template>

<script>
export default {
  props: ['detail'],
}
</script>

<style lang="scss">
.service-detail-title {
  padding: 0 15px;
  text-align: center;
  font-size: 29px;
  font-family: Oswald;
  font-weight: 300;
  margin: 0;
  margin-bottom: 10px;
  line-height: 1;
  color: #7a7a7a;
}
</style>
