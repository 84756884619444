var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"offer"}},[_c('div',{staticClass:"avatar",style:({ backgroundImage: ("url(" + (_vm.offer.avatar) + ")") })}),_c('div',{staticClass:"attributes"},[(_vm.isChildOffersAvailable && !_vm.useItemCarousel)?_c('div',{staticClass:"sub-selector"},[_c('md-field',[_c('label',{attrs:{"for":"select"}},[_vm._v(_vm._s(_vm.$stripHtml(_vm.offer.description)))]),_c('md-select',{attrs:{"name":"select"},model:{value:(_vm.$save.selectedChildId),callback:function ($$v) {_vm.$set(_vm.$save, "selectedChildId", $$v)},expression:"$save.selectedChildId"}},_vm._l((_vm.availableChildOffers),function(child){return _c('md-option',{key:child.id,attrs:{"value":child.id}},[_vm._v(_vm._s(child.title))])}),1)],1)],1):_vm._e(),(
        _vm.useItemCarousel &&
          (_vm.isChildOffersAvailable ||
            (_vm.offer.childOffers && _vm.offer.childOffers.length > 0))
      )?_c('div',{staticClass:"sub-selector-carousel without-padding"},[_c('ItemCarousel',{attrs:{"items":_vm.isChildOffersAvailable ? _vm.availableChildOffers : _vm.offer.childOffers,"disabled":!_vm.isChildOffersAvailable},model:{value:(_vm.$save.selectedChildId),callback:function ($$v) {_vm.$set(_vm.$save, "selectedChildId", $$v)},expression:"$save.selectedChildId"}})],1):_vm._e(),(_vm.$save.selectedChild != null)?_c('div',{staticClass:"sub-attrs"},_vm._l((_vm.$save.selectedChild.attributes),function(attribute){return _c('AttributeWrapper',{key:attribute.id,attrs:{"attribute":attribute}})}),1):_vm._e(),(_vm.$save.selectedChild && _vm.$save.selectedChild.childOffers)?_c('div',{staticClass:"sub-sub-selector"},[_c('md-field',[_c('label',{attrs:{"for":"select2"}},[_vm._v(_vm._s(_vm.$stripHtml(_vm.$save.selectedChild.description)))]),_c('md-select',{attrs:{"name":"select2"},model:{value:(_vm.$save.selectedSubChildId),callback:function ($$v) {_vm.$set(_vm.$save, "selectedSubChildId", $$v)},expression:"$save.selectedSubChildId"}},_vm._l((_vm.$save.selectedChild.childOffers),function(child){return _c('md-option',{key:child.id,attrs:{"value":child.id}},[_vm._v(_vm._s(child.title))])}),1)],1)],1):_vm._e(),_c('div',{directives:[{name:"html-reactive-links",rawName:"v-html-reactive-links"}],staticClass:"description",domProps:{"innerHTML":_vm._s(
        _vm.$save.selectedSubChild != null &&
        _vm.$save.selectedSubChild.description != null
          ? _vm.getFormattedDescription(_vm.$save.selectedSubChild.description)
          : ''
      )}}),(!_vm.hideRootAttributes)?_c('div',{staticClass:"root-attributes"},_vm._l((_vm.offer.attributes),function(attribute){return _c('AttributeWrapper',{key:attribute.id,attrs:{"attribute":attribute,"offer":_vm.offer,"subOffer":_vm.$save.selectedChild,"subSubOffer":_vm.$save.selectedSubChild}})}),1):_vm._e(),(_vm.$save.selectedSubChildId !== null)?_c('div',{staticClass:"sub-sub-attrs"},_vm._l((_vm.$save.selectedSubChild.attributes),function(attribute){return _c('AttributeWrapper',{key:attribute.id,attrs:{"attribute":attribute}})}),1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }