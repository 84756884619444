<template>
  <div
    class="room-breakfast-item"
    :class="{
      carousel,
      animation,
      collapsed: !item.reusable && alreadyInCart,
      active: nextActive,
    }"
    ref="root"
  >
    <div class="general">
      <div class="avatar-wrap">
        <div
          class="avatar"
          :class="{ hasImage }"
          :style="{
            backgroundImage: `url(${
              hasImage ? item.avatar : require('@/assets/FoodStub1.png')
            })`,
          }"
          @click="hasImage ? openImage() : () => {}"
        />
      </div>

      <div class="info">
        <div class="header">
          <div class="title">{{ item.title }}</div>

          <div class="controls" v-if="$store.user && $store.user.crmUser">
            <el-switch
              v-model="{ active: nextActive }.active"
              @change="toggleActive"
              :disabled="togglingActive"
            />
          </div>
        </div>
        <div class="description">{{ $stripHtml(item.description) }}</div>

        <RoomBreakfastItemButtons
          v-if="!carousel"
          :item="item"
          :currentItem="currentItem"
          :current="currentQtyAttr ? currentQtyAttr.value : null"
          :carousel="carousel"
          @increaseItem="increaseItem"
          @decreaseItem="decreaseItem"
          @addToFinal="addToFinal"
          :disableBuying="disableBuying"
        />
      </div>
    </div>

    <RoomBreakfastItemButtons
      v-if="carousel"
      :item="item"
      :currentItem="currentItem"
      :current="currentQtyAttr ? currentQtyAttr.value : null"
      :carousel="carousel"
      @increaseItem="increaseItem"
      @decreaseItem="decreaseItem"
      @addToFinal="addToFinal"
      :disableBuying="disableBuying"
    />
  </div>
</template>

<script>
import RoomBreakfastItemButtons from '@/components/Services/RoomBreakfast/ItemButtons'

export default {
  props: ['item', 'carousel', 'order', 'disableBuying'],
  components: { RoomBreakfastItemButtons },
  data() {
    return {
      currentItem: {},
      animation: false,
      togglingActive: false,
    }
  },
  created() {
    this.resetCurrentItem()
    this.watchAttributes()
  },
  beforeDestroy() {
    for (let attr of this.currentItem.attributeValues) {
      if (attr['@type'] == 'qty') continue
      const saveAttr = this.$store.attributesSaves[attr.id]
      if (saveAttr) saveAttr.value = saveAttr.defaultValue
    }
  },
  events: {
    'attr-value-changed'(attrId) {
      const saves = this.$store.attributesSaves
      for (const attr of this.currentItem.attributeValues) {
        if (attrId == attr.id) attr.value = saves[attrId].getValue()
      }
    },
  },
  methods: {
    async toggleActive() {
      this.togglingActive = true
      try {
        await this.$api.post('crm/offer/state', {
          offerId: this.item.id.split('_')[0],
          active: !this.item.active,
        })
      } catch {
        this.togglingActive = false
      } finally {
        this.$events.emit('update-service')

        setTimeout(() => {
          this.togglingActive = false
        }, 2000)
      }
    },
    watchAttributes() {
      const saves = this.$store.attributesSaves
      for (const attr of this.currentItem.attributeValues) {
        if (saves[attr.id]) attr.value = saves[attr.id].getValue()
      }
    },
    resetCurrentItem() {
      this.currentItem = {
        offerId: this.item.id,
        priceValue: this.item.priceValue,
        priceCurrency: this.item.priceCurrency,
        attributeValues: this.item.attributes.map(attr => {
          return {
            ...attr,
            value: attr['@type'] != 'qty' ? attr.defaultValue : 0,
          }
        }),
      }
    },
    increaseItem() {
      let max = this.qtyAttr.max
      let min = this.qtyAttr.min

      this.currentQtyAttr.value++

      if (this.currentQtyAttr.value < min) this.currentQtyAttr.value = min
      if (this.currentQtyAttr.value > max) this.currentQtyAttr.value = max

      this.$forceUpdate()
    },
    decreaseItem() {
      let max = this.qtyAttr.max
      let min = this.qtyAttr.min

      this.currentQtyAttr.value--

      if (this.currentQtyAttr.value < min) this.currentQtyAttr.value = 0
      if (this.currentQtyAttr.value > max) this.currentQtyAttr.value = max

      this.$forceUpdate()
    },
    addToFinal() {
      this.$emit('addToFinal', this.currentItem)

      const addedCb = item => {
        if (this.item.id != item.offerId)
          return this.$events.once('added-to-final', addedCb)

        this.resetCurrentItem()
        this.$nextTick(this.animate)
      }
      this.$events.once('added-to-final', addedCb)
    },
    animate() {
      let duration = 1000
      let el = this.$refs.root
      this.$events.emit(
        'animate-breakfast-item',
        this.item,
        this.carousel,
        el.getBoundingClientRect(),
        duration,
      )
      el.style.transition = ''
      this.animation = true
      setTimeout(() => {
        el.style.transition = `${duration}ms all`
        this.animation = false
      }, duration)
    },
    openImage() {
      this.$events.emit('show-images', {
        src: this.item.avatar,
        alt: this.item.title,
      })
    },
  },
  computed: {
    currentQtyAttr() {
      if (!this.currentItem.attributeValues) return null
      return this.currentItem.attributeValues.filter(
        attr => attr['@type'] == 'qty',
      )[0]
    },
    qtyAttr() {
      return this.item.attributes.filter(attr => attr['@type'] == 'qty')[0]
    },
    hasImage() {
      return this.item.avatar && !this.item.avatar.endsWith('/')
    },
    alreadyInCart() {
      return !!this.order?.orderItem.orderItems.find(
        item => item.offerId == this.item.id.split('_')[0],
      )
    },
    nextActive() {
      return this.togglingActive ? !this.item.active : this.item.active
    },
  },
  watch: {
    alreadyInCart() {
      setTimeout(() => {
        this.$emit('updateCategoriesScroll')
      }, 500)
    },
  },
}
</script>

<style lang="scss">
.room-breakfast-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  opacity: 1;
  transform: translateX(0) translateY(0) scale(1);
  background-color: white;
  margin-top: 25px;

  &.animation {
    opacity: 0;
  }

  &:not(.carousel) {
    margin-top: 25px;

    > .general {
      > .info {
        min-width: 200px;
      }
    }
  }

  &.carousel {
    max-width: 280px;
    position: relative;
    overflow: hidden;
    margin-right: 15px;

    box-shadow: 0px 1px 4px 0px rgba(34, 60, 80, 0.3);
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 8px;
    padding: 10px;
    min-width: 80%;

    > .general {
      > .info {
        > .header {
          flex-direction: column-reverse;
        }
      }
    }
  }

  transition: max-height 0.5s, max-width 0.5s, min-width 0.5s, padding 0.5s,
    margin 0.5s, margin-top 0.5s, overflow 0.5s;
  max-height: 320px;

  &.collapsed:not(.carousel) {
    margin-top: 0;
    max-height: 0px;
    overflow: hidden;
  }

  &.collapsed.carousel {
    margin: 0;
    max-width: 0;
    min-width: 0;
    padding: 0;
    overflow: hidden;
  }

  &:not(.active) {
    > .general {
      > .avatar-wrap,
      > .info .header .title,
      > .info .description {
        opacity: 0.6;
      }
    }
  }

  > .general {
    position: relative;
    max-width: 370px;
    width: 100%;
    display: flex;
    flex-direction: row;
    white-space: normal;
    padding-right: 10px;

    .avatar-wrap {
      margin-right: 15px;
      transition: 0.5s all;

      .avatar {
        min-height: 110px;
        min-width: 110px;
        border-radius: 8.5px;
        box-shadow: 0px 2px 8px 0px rgba(34, 60, 80, 0.3);

        background-size: cover;
        background-position: center;

        &.hasImage {
          cursor: pointer;
        }
      }
    }

    .info {
      cursor: default;
      max-width: 200px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 5px;

      > .header {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .title {
          color: #47525e;
          font-family: Roboto;
          font-size: 16px;
          line-height: 1.1;
          text-align: left;
          word-break: break-word;
          font-weight: 500;
          opacity: 1;
          transition: 0.5s all;
        }
      }

      .description {
        color: #8492a6;
        font-family: Roboto;
        font-size: 13px;
        line-height: 14px;
        transition: 0.5s all;
      }
    }
  }
}
</style>
