<template>
  <div id="service">
    <Loading v-if="loading" />
    <component :is="components[type]" :id="id" :service="service" />
    <AgeRestrictionWindow
      :service="service"
      v-if="service && service.showAgreement && !agreeAgeRestriction"
      @agreed="
        () => {
          agreeAgeRestriction = true
        }
      "
    />
  </div>
</template>

<script>
import Common from '@/components/Services/Common'
import RoomService from '@/components/Services/RoomService'
import RoomBreakfast from '@/components/Services/RoomBreakfast'
import CommonCart from '@/components/Services/CommonCart'
import AgeRestrictionWindow from '../components/AgeRestrictionWindow.vue'

export default {
  props: ['id'],
  components: { Common, RoomService, RoomBreakfast, AgeRestrictionWindow },
  data() {
    return {
      loading: true,
      service: null,
      type: '',
      agreeAgeRestriction: localStorage.agreeAgeRestriction,
      components: {
        FOOD: RoomBreakfast,
        FOOD2: RoomBreakfast,
        ROOM_SERVICE: RoomService,
        ONCE: Common,
        COMMON: Common,
        SERVICE_DESK: Common,
        COMMON_BIG: Common,
        COMMON_CART: CommonCart,
        BOOKING: Common,
        SALES: Common,
        EXCURSION: Common,
        SPA: Common,
        MAP: Common,
      },
    }
  },
  created() {
    this.$api.get(`services/${this.id}`).then(res => {
      const service = (this.service = res.data.data)
      this.type = service.type
      this.$events.emit('set-theme', service.style ?? 'default')
      this.$events.emit('log-event', 'show_service', {
        service: this.id,
        title: service.title,
      })
      this.loading = false
    })
  },
}
</script>

<style lang="scss">
#service {
  padding: 0;
}
</style>
