var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.viewportWidth > 0)?_c('div',{staticClass:"common-food-item",style:({
    width: _vm.itemWidth + 'px',
    minWidth: _vm.itemWidth + 'px',
    maxWidth: _vm.itemWidth + 'px',
    left: _vm.xPos + 'px',
    transition: _vm.animated ? '0.25s all' : 'none',
  })},[_c('div',{staticClass:"item-card"},[(_vm.hasImage)?_c('div',{staticClass:"avatar",style:({
        backgroundImage: ("url(" + (_vm.item.avatar) + ")"),
      })}):_vm._e(),_c('div',{staticClass:"info"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.item.title))]),_c('div',{staticClass:"description"},[_vm._v(_vm._s(_vm.$stripHtml(_vm.item.description)))])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }