<template>
  <div id="room-breakfast" v-if="details && details.offer && !loading">
    <TopMenu
      :title="details.offer.title"
      :avatar="service.avatar"
      :showLangSelector="true"
      :invertGradient="true"
      :noDim="true"
      ref="topMenu"
      :customBack="
        () => {
          $router.push('/hotel/' + service.propertyId)
        }
      "
    />

    <RoomBreakfastCategories
      ref="categories"
      :categories="categories"
      :service="service"
      :activeCategory="activeCategory"
      :topMenuHeight="topMenuHeight"
      @setActiveCategoryById="setActiveCategoryById"
    />

    <div class="items">
      <div
        v-for="item in activeCategory.childOffers"
        :key="item.id + item.active"
        class="item"
        :class="{ carousel: item.childOffers && item.childOffers.length > 0 }"
      >
        <RoomBreakfastItem
          v-if="!item.childOffers || item.childOffers.length <= 0"
          :item="item"
          :order="order"
          @addToFinal="addToFinal"
          :disableBuying="disableBuying"
        />

        <RoomBreakfastItemCarousel
          v-else
          :description="item.description"
          :items="item.childOffers"
          :order="order"
          @addToFinal="addToFinal"
          :disableBuying="disableBuying"
        />
      </div>
    </div>

    <LegalInfo :text="details.offer.legalInfo" />

    <NextButton
      v-if="order && order.orderItem.orderItems.length > 0"
      :click="next"
      :label="$t('order.place')"
      :floating="true"
      :onlyFloating="true"
      :floatingCounter="order.orderItem.orderItems.length"
      :floatingLabel="
        order.priceTotal > 0 ? order.priceTotal + ' ' + order.priceCurrency : ''
      "
      floatingIcon="shopping_cart"
    />

    <RoomBreakfastItem
      class="animation-item"
      v-if="animationItem"
      :item="animationItem"
      :carousel="animationItemCarousel"
      ref="animationItem"
    />
  </div>
  <Loading v-else />
</template>

<script>
import TopMenu from '@/components/TopMenu'
import NextButton from '@/components/NextButton'
import LegalInfo from '@/components/LegalInfo'
import RoomBreakfastItem from '@/components/Services/RoomBreakfast/Item'
import RoomBreakfastItemCarousel from '@/components/Services/RoomBreakfast/ItemCarousel'
import RoomBreakfastCategories from '@/components/Services/RoomBreakfast/Categories'

export default {
  props: ['id'],
  components: {
    TopMenu,
    NextButton,
    LegalInfo,
    RoomBreakfastItem,
    RoomBreakfastItemCarousel,
    RoomBreakfastCategories,
  },
  data() {
    return {
      service: null,
      details: null,

      order: null,

      categories: [],
      activeCategory: null,

      topMenuHeight: 60,
      loading: true,

      animationItem: null,
      animationItemCarousel: false,
    }
  },
  created() {
    this.$store.roomBreakfastAddingToFinal = false
  },
  mounted() {
    this.updateService(true)
  },
  events: {
    'update-service'() {
      this.updateService()
    },
    authorized() {
      if (!this.isAuthorized()) this.order = null
    },
    'animate-breakfast-item'(item, carousel, rect, duration) {
      this.animationItem = item
      this.animationItemCarousel = carousel

      this.$nextTick(() => {
        if (!this.$refs.animationItem) return
        let el = this.$refs.animationItem.$el
        el.style.transition = ''
        el.style.opacity = '1'
        el.style.transform = 'scale(1)'
        el.style.top = rect.y + 'px'
        el.style.left = rect.x + 'px'
        el.style.width = rect.width + 'px'
        el.style.height = rect.height + 'px'

        setTimeout(() => {
          el.style.transition = `${duration}ms all`
          el.style.opacity = '0'
          el.style.transform = 'scale(.15)'
          el.style.top = window.innerHeight - rect.height / 2 + 'px'
          el.style.left = window.innerWidth - rect.width / 2 - 50 + 'px'

          setTimeout(() => {
            el.style.transition = ''
            el.style.opacity = '1'
            el.style.transform = 'scale(1)'
            this.animationItem = null
          }, duration)
        }, 50)
      })
    },
  },
  methods: {
    updateService(restoryCategory = false) {
      this.$api.get(`services/${this.id}`).then(res => {
        this.service = res.data.data
        this.details = {}
        for (const key of Object.keys(this.service.serviceDetails)) {
          const detail = this.service.serviceDetails[key]
          const type = detail['@type'] ? detail['@type'].toLowerCase() : key

          let content = detail
          if (detail[type]) content = detail[type]
          if (detail['value']) content = detail['value']

          this.details[type] = content
        }

        if (!this.details.offer) {
          this.$router.go(-1)
          throw Error(`RoomBreakfast service doesn't has offer [${this.id}]`)
        }

        this.$api
          .get(`offers/${this.details.offer.id}`)
          .then(res => {
            this.loading = false
            this.categories = res.data.data.childOffers ?? []

            if (this.activeCategory)
              this.activeCategory = this.categories.find(
                cat => cat.id == this.activeCategory.id,
              )
            if (!this.activeCategory)
              this.activeCategory =
                this.categories?.length > 0 ? this.categories[0] : {}

            if (restoryCategory)
              this.$nextTick(this.$refs.categories.restoreQueryCategory)
          })
          .catch(() => {
            this.loading = false
          })

        let orderIdSave = this.$sessionStorage.breakfastOrderIdSaves[
          this.service.id
        ]
        if (orderIdSave && this.isAuthorized())
          this.$api.get(`user/orders/${orderIdSave}`).then(res => {
            this.order = res.data.data
          })
      })

      this.updateTopMenuHeight()
    },
    isAuthorized() {
      return (
        this.$store.isAuthed ||
        this.$api.defaults.headers.common['x-customer-id'] != null
      )
    },
    addToFinal(item) {
      this.$store.roomBreakfastAddingToFinal = true
      this.$api
        .post(`user/orders`, {
          action: this.order ? 'UPDATE' : 'CREATE',
          orderId: this.order ? this.order.orderId : undefined,
          serviceId: this.id,
          availablePaymentMethods: true,
          skipTimeSlotValidation: !!this.order,
          orderItemRequests: [{ ...item, action: 'ADD', validate: true }],
        })
        .then(res => {
          this.order = res.data.data
          this.$sessionStorage.breakfastOrderIdSaves[
            this.service.id
          ] = this.order?.orderId
          this.$events.emit('added-to-final', item)

          for (let attr of item.attributeValues) {
            if (attr['@type'] == 'qty') continue
            const saveAttr = this.$store.attributesSaves[attr.id]
            if (saveAttr) saveAttr.setValue(saveAttr.defaultValue)
          }
        })
        .catch(err => {
          if (!this.$store.isLoginError(err))
            this.$events.emit(
              'show-alert',
              this.$t('errors.error'),
              err.response.data.message,
            )
          else this.$events.once('authorized', () => this.addToFinal(item))
        })
        .finally(() => {
          this.$store.roomBreakfastAddingToFinal = false
        })
    },
    next() {
      if (this.order) this.$router.push(`/order?orderId=${this.order.orderId}`)
    },
    updateTopMenuHeight() {
      let topMenu = this.$refs.topMenu?.$refs.fixed
      this.topMenuHeight = topMenu ? topMenu.getBoundingClientRect().height : 60
    },
    setActiveCategoryById(categoryId) {
      for (let category of this.categories)
        if (category.id == categoryId) this.activeCategory = category
    },
    _scroll() {
      this.updateTopMenuHeight()
    },
  },
  computed: {
    disableBuying() {
      return !this.details.offer.hasPaymentMethods
    },
  },
}
</script>

<style lang="scss">
#room-breakfast {
  > .items {
    display: flex;
    flex-direction: column;
    align-items: center;

    > .item {
      width: 100%;
      max-width: 370px;
      padding-left: 15px;
    }
    > .carousel {
      padding: unset;
      max-width: unset;
    }
  }

  > .legal-info {
    padding-bottom: 75px;
  }

  > .animation-item {
    position: fixed;
    z-index: 1;
    min-width: unset;
  }
}
</style>
