<template>
  <Loading v-if="!offer && preloadOffer" />
  <div id="common-food-service" v-else>
    <div class="content">
      <TopMenu
        :title="service.title"
        :avatar="service.avatar"
        :description="service.description"
        :customBack="
          () => {
            $router.push('/hotel/' + service.propertyId)
          }
        "
      />
      <div class="service-details" style="margin-top: 10px">
        <ServiceDetail
          v-for="detail in service.serviceDetails"
          :key="detail.key"
          :detail="detail"
          :offer="offer"
          :order="order"
          :offerProps="{ useItemCarousel: true, hideRootAttributes: true }"
        />
      </div>
    </div>

    <div class="menu" :style="{ width: menuWidth + 'px' }">
      <NextButton
        v-if="canAddToOrder"
        :click="addToOrder"
        :disabled="addingToOrder"
        :floating="true"
        :onlyFloating="true"
        :floatingLabel="$t('order.addToOrder')"
        :floatingWithoutFixed="true"
        :withoutMarginRight="true"
      />

      <NextButton
        v-if="order && order.orderItem.orderItems.length > 0"
        :click="next"
        :label="$t('order.place')"
        :floating="true"
        :onlyFloating="true"
        :floatingWithoutFixed="true"
        floatingIcon="shopping_cart"
        :withoutMarginRight="true"
        :floatingCounter="orderItemsCount"
        :floatingLabel="
          order.priceTotal > 0
            ? order.priceTotal + ' ' + order.priceCurrency
            : ''
        "
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import TopMenu from '@/components/TopMenu'
import NextButton from '@/components/NextButton'
import ServiceDetail from '@/components/ServiceDetail'

export default {
  name: 'CommonCart',
  props: ['id', 'service'],
  components: {
    TopMenu,
    NextButton,
    ServiceDetail,
  },
  data() {
    return {
      offer: null,
      order: null,
      addingToOrder: false,
      menuWidth: this.$full ? this.routerWidth : window.innerWidth,
    }
  },
  async created() {
    this.initSave()
    this.restoreOrder()
    if (this.preloadOffer) await this.fetchOffer()
    await this.resetChildOffersAndAttributes()

    await this.$waitFor(() => this.routerWidth > 0)
    this.updateMenuWidth()
  },
  events: {
    authorized() {
      if (!this.isAuthorized()) this.order = null
    },
    'want-add-to-order'() {
      this.addToOrder({ calledFromEvent: true })
    },
  },
  methods: {
    async fetchOffer() {
      const resp = await this.$api.get(`offers/${this.preloadOffer.id}`)
      this.offer = resp.data.data
    },
    initSave() {
      const offerId = this.preloadOffer.id
      const saves = this.$store.offerSaves
      if (!saves[offerId]) {
        saves[offerId] = {
          selectedChildId: null,
          selectedChild: null,
          selectedSubChildId: null,
          selectedSubChild: null,
        }
      }
      this.$save = Vue.observable(saves[offerId])
    },
    async selectChild(childOffer) {
      await this.$waitFor(() => this.$save)
      this.$save.selectedChildId = childOffer.id
      this.$save.selectedChild = childOffer
    },
    async selectSubChild(subChildOffer) {
      await this.$waitFor(() => this.$save)
      if (!this.$save.selectedChild) return

      const foundSubChildOffer = this.$save.selectedChild.childOffers.find(
        offer => offer.id == subChildOffer.id,
      )
      if (!foundSubChildOffer) return

      this.$save.selectedSubChildId = foundSubChildOffer.id
      this.$save.selectedSubChild = foundSubChildOffer
    },
    async restoreOrder() {
      let orderIdSave = this.$sessionStorage.commonCartOrderIdSaves[
        this.service.id
      ]
      if (orderIdSave && this.isAuthorized()) {
        const resp = await this.$api.get(`user/orders/${orderIdSave}`)
        this.order = resp.data.data
      }
    },
    async resetChildOffersAndAttributes() {
      if (this.offer.childOffers && !this.$save.selectedChildId)
        await this.selectChild(this.offer.childOffers[0])

      if (this.$save.selectedChild?.childOffers)
        this.selectSubChild(this.$save.selectedChild.childOffers[0])

      for (const attr of this.getAllAtributes()) {
        const saveAttr = this.$store.attributesSaves[attr.id]
        if (saveAttr) saveAttr.setValue(saveAttr.defaultValue)
      }
    },
    getAllAtributes(offer = this.offer) {
      const allAtributes = [...offer.attributes]

      if (offer.childOffers) {
        for (const childOffer of offer.childOffers) {
          allAtributes.push(this.getAllAtributes(childOffer))
        }
      }

      return allAtributes
    },
    getAttributeValuesFromOffer(offer) {
      return offer.attributes.map(attr => ({
        ...attr,
        value: this.$store.attributesSaves[attr.id].getValue(),
      }))
    },
    addToOrder(options = {}) {
      if (this.addingToOrder || !this.canAddToOrder) return
      this.addingToOrder = true
      const orderItemRequests = [
        this.$save.selectedChild,
        this.$save.selectedSubChild,
      ]
        .filter(offer => offer)
        .map(offer => ({
          action: 'ADD',
          offerId: offer.id,
          attributeValues: this.getAttributeValuesFromOffer(offer),
        }))

      this.$api
        .post(`user/orders`, {
          action: this.order ? 'UPDATE' : 'CREATE',
          orderId: this.order ? this.order.orderId : undefined,
          serviceId: this.id,
          availablePaymentMethods: true,
          skipTimeSlotValidation: !!this.order,
          orderItemRequests,
        })
        .then(res => {
          this.$events.emit('add-to-order', this.$save.selectedChild, options)
          this.resetChildOffersAndAttributes()
          this.order = res.data.data

          this.$sessionStorage.commonCartOrderIdSaves[
            this.service.id
          ] = this.order?.orderId
        })
        .catch(err => {
          if (!this.$store.isLoginError(err))
            this.$events.emit(
              'show-alert',
              this.$t('errors.error'),
              err.response.data.message,
            )
          else
            this.$events.emit('do-login-popup', {
              callback: () => this.addToOrder(),
              noConfirm: true,
            })
        })
        .finally(() => {
          setTimeout(() => {
            this.addingToOrder = false
          }, 800)
        })
    },
    next() {
      if (this.order) this.$router.push(`/order?orderId=${this.order.orderId}`)
    },
    isAuthorized() {
      return (
        this.$store.isAuthed ||
        this.$api.defaults.headers.common['x-customer-id'] != null
      )
    },
    updateMenuWidth() {
      this.menuWidth = this.$full ? this.routerWidth : window.innerWidth
    },
    _resize() {
      this.updateMenuWidth()
    },
  },
  computed: {
    orderItemsCount() {
      if (!this.order) return 0
      return this.order.orderItem.orderItems.filter(item => {
        const foundItem = this.offer.childOffers.find(
          childOffer => childOffer.id.split('_')[0] == item.offerId,
        )
        return !foundItem || !foundItem.childOffers
      }).length
    },
    preloadOffer() {
      return this.service.serviceDetails.find(
        detail => detail['@type'] == 'OFFER',
      )?.offer
    },
    routerWidth() {
      return this.$store.routerWidth
    },
    canAddToOrder() {
      if (!this.offer || !this.offer.hasPaymentMethods) return false
      if (!this.order) return true

      const cartIds = this.order.orderItem.orderItems.map(item => item.offerId)
      const containsItemThatStilNotOrdered = !!this.offer.childOffers.find(
        item => !cartIds.includes(item.id.split('_')[0]),
      )
      const containsReusableItem = !!this.offer.childOffers.find(
        item => item.reusable,
      )

      return containsReusableItem || containsItemThatStilNotOrdered
    },
  },
  watch: {
    routerWidth() {
      this.updateMenuWidth()
    },
  },
}
</script>

<style lang="scss" scoped>
#common-food-service {
  padding-bottom: 60px;
  > .content {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  > .menu {
    z-index: 2;
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 15px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
</style>
