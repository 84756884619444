<template>
  <div class="service-detail-offer">
    <Offer :offer="offer || detail.offer" :order="order" v-bind="offerProps" />
  </div>
</template>

<script>
import Offer from '@/components/Offer'

export default {
  components: { Offer },
  props: ['detail', 'offer', 'offerProps', 'order'],
}
</script>
