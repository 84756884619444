<template>
  <div class="order-attributes" :class="{ oneLine }">
    <div class="attribute" v-for="attr in attributesCopy" :key="attr.id">
      <span v-if="['string', 'number'].includes(typeof format(attr))"
        >{{ attr.title }}: {{ format(attr) }}</span
      >

      <span
        v-if="typeof format(attr) == 'object'"
        @click="
          () => {
            format(attr).func()
          }
        "
        :class="format(attr).class"
        :style="format(attr).style"
        >{{ format(attr).label }}</span
      >

      <span v-if="oneLine && attr != lastAttribute">, </span>
    </div>
  </div>
</template>

<script>
import formatTimeDisplay from '@/lib/formatTimeDisplay'
import { formatDate } from '../lib/formatDate'

export default {
  props: ['attributes', 'oneLine', 'hideQty', 'showBottomLine'],
  data() {
    return {
      attributesCopy: [],
    }
  },
  watch: {
    attributes: {
      deep: true,
      immediate: true,
      handler(attributes) {
        if (attributes) {
          this.attributesCopy = JSON.parse(JSON.stringify(attributes))
        }
      },
    },
  },
  computed: {
    lastAttribute() {
      return this.attributesCopy[this.attributesCopy.length - 1]
    },
  },
  methods: {
    format(attr) {
      if (
        typeof attr.value == 'undefined' &&
        typeof attr.defaultValue != 'undefined'
      )
        attr.value = attr.defaultValue
      if (attr.value == 'true') attr.value = true
      if (attr.value == '0') attr.value = 0
      if (
        attr.hidden ||
        typeof attr.value == 'undefined' ||
        (attr['@type'] == 'check' && attr.value != true) ||
        (attr['@type'] == 'privacy_policy' && attr.value != true) ||
        (attr['@type'] == 'qty' && attr.value == 0) ||
        (attr['@type'] == 'qty' && this.hideQty) ||
        (attr['@type'] == 'date_time' && attr.value == 0) ||
        (attr['@type'] == 'slots' && !attr.value.startTime) ||
        (attr['@type'] == 'slots' && !attr.value.endTime) ||
        attr.value == null
      ) {
        this.attributesCopy = this.attributesCopy.filter(
          filterAttr => filterAttr,
        )
        this.attributesCopy = this.attributesCopy.filter(
          filterAttr => filterAttr.id != attr.id,
        )
        return
      }
      let value = attr.value

      let type = attr['@type']

      if (type == 'check') value = { label: attr.title }
      if (type == 'date') {
        let date = new Date()
        date.setTime(value)
        value = date.normalize(true).toLocaleDateString()
      }
      if (type == 'date_time') {
        let date = new Date()
        date.setTime(value)
        date = date.normalize(true)
        let time = date
          .toTimeString()
          .split(' ')[0]
          .split(':')
        time.pop()
        return [date.toLocaleDateString(), time.join(':')].join(' ')
      }

      if (type == 'slots') {
        const { startTime, endTime } = attr.value
        const startTimeNormalized = new Date(startTime).normalize(true)
        const endTimeNormalized = new Date(endTime).normalize(true)

        const sameDay =
          formatDate(startTimeNormalized) == formatDate(endTimeNormalized)

        const startTimeStr = startTimeNormalized.toLocaleString(
          this.$t('datetimeFormat'),
          {
            timeStyle: 'short',
            dateStyle: 'short',
          },
        )
        let endTimeStr = endTimeNormalized.toLocaleString(
          this.$t('datetimeFormat'),
          {
            timeStyle: 'short',
            dateStyle: 'short',
          },
        )
        if (sameDay) {
          endTimeStr = endTimeNormalized.toLocaleTimeString(
            this.$t('datetimeFormat'),
            {
              timeStyle: 'short',
            },
          )
        }

        return [startTimeStr, endTimeStr].join(' - ')
      }

      if (type == 'qty') {
        value = formatTimeDisplay(Number(value), attr.unit, key => this.$t(key))
      }

      if (type == 'list' && attr.values) {
        for (let attrValue of attr.values) {
          if (Object.keys(attrValue)[0] == value)
            value = Object.values(attrValue)[0]
        }
      }

      if (type == 'privacy_policy') {
        value = {
          label: attr.title,
          class: 'link',
          func: () => {
            this.$events.emit('show-alert', attr.title, attr.subTitle)
          },
        }
      }

      return value
    },
  },
}
</script>

<style lang="scss">
.order-attributes {
  color: #7a7a7a;
  font-size: 15px;
  display: inline;
  white-space: pre-wrap;

  &.oneLine {
    font-size: 12px;

    .attribute {
      display: inline;
    }
  }

  .link {
    color: var(--md-theme-default-primary-on-background, #448aff);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .attribute {
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
</style>
