<template>
  <span
    v-html="text"
    v-html-reactive-links
    class="legal-info"
    :class="{
      'legal-info-short': short,
    }"
  ></span>
</template>

<script>
export default {
  props: ['text', 'short'],
}
</script>

<style lang="scss">
.legal-info {
  display: block;
  width: 90%;
  margin: 0 auto;
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.legal-info-short {
  margin-left: 0 !important;
}
</style>
