var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"root",staticClass:"room-breakfast-item",class:{
    carousel: _vm.carousel,
    animation: _vm.animation,
    collapsed: !_vm.item.reusable && _vm.alreadyInCart,
    active: _vm.nextActive,
  }},[_c('div',{staticClass:"general"},[_c('div',{staticClass:"avatar-wrap"},[_c('div',{staticClass:"avatar",class:{ hasImage: _vm.hasImage },style:({
          backgroundImage: ("url(" + (_vm.hasImage ? _vm.item.avatar : require('@/assets/FoodStub1.png')) + ")"),
        }),on:{"click":function($event){_vm.hasImage ? _vm.openImage() : function () {}}}})]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.item.title))]),(_vm.$store.user && _vm.$store.user.crmUser)?_c('div',{staticClass:"controls"},[_c('el-switch',{attrs:{"disabled":_vm.togglingActive},on:{"change":_vm.toggleActive},model:{value:({ active: _vm.nextActive }.active),callback:function ($$v) {_vm.$set({ active: _vm.nextActive }, "active", $$v)},expression:"{ active: nextActive }.active"}})],1):_vm._e()]),_c('div',{staticClass:"description"},[_vm._v(_vm._s(_vm.$stripHtml(_vm.item.description)))]),(!_vm.carousel)?_c('RoomBreakfastItemButtons',{attrs:{"item":_vm.item,"currentItem":_vm.currentItem,"current":_vm.currentQtyAttr ? _vm.currentQtyAttr.value : null,"carousel":_vm.carousel,"disableBuying":_vm.disableBuying},on:{"increaseItem":_vm.increaseItem,"decreaseItem":_vm.decreaseItem,"addToFinal":_vm.addToFinal}}):_vm._e()],1)]),(_vm.carousel)?_c('RoomBreakfastItemButtons',{attrs:{"item":_vm.item,"currentItem":_vm.currentItem,"current":_vm.currentQtyAttr ? _vm.currentQtyAttr.value : null,"carousel":_vm.carousel,"disableBuying":_vm.disableBuying},on:{"increaseItem":_vm.increaseItem,"decreaseItem":_vm.decreaseItem,"addToFinal":_vm.addToFinal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }