<template>
  <div class="room-breakfast-item-buttons" :class="{ carousel }">
    <div class="buy" v-if="current != null">
      <div class="decrease" v-if="!disableBuying" @click="decreaseItem">
        <md-icon>remove</md-icon>
      </div>
      <div class="current" v-if="!disableBuying">
        <span>{{ current }}</span>
      </div>
      <div
        class="increase"
        :class="{ showPrice: item.priceValue > 0, canBuy: !disableBuying }"
        @click="increaseItem"
      >
        <div class="price">
          <div class="icon" v-if="!disableBuying"><md-icon>add</md-icon></div>
          <div class="value">
            {{ item.priceValue }}
            <span class="currency">{{ item.priceCurrency }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="additional" v-if="hasAdditional" @click="showAdditionalPopup">
      <div class="select">{{ $t('order.selectAdditional') }}</div>

      <div class="attributes">
        <OrderAttributes
          v-if="currentItem.attributeValues.length > 1"
          :attributes="currentItem.attributeValues"
          :hideQty="true"
          :oneLine="true"
          :showBottomLine="current > 0"
        />
      </div>
    </div>

    <div
      class="add"
      v-if="current > 0"
      @click="addToFinal"
      :class="{ hasAdditional, disabled }"
    >
      <div class="label">{{ $t('order.addToOrder') }}</div>
    </div>
  </div>
</template>

<script>
import OrderAttributes from '@/components/OrderAttributes'

export default {
  components: { OrderAttributes },
  props: ['item', 'currentItem', 'current', 'carousel', 'disableBuying'],
  methods: {
    showAdditionalPopup() {
      this.$events.emit(
        'show-attributes',
        this.item.attributes.filter(attr => attr['@type'] != 'qty'),
      )
    },
    increaseItem() {
      if (this.disableBuying) return
      this.$emit('increaseItem')
      this.$forceUpdate()
    },
    decreaseItem() {
      this.$emit('decreaseItem')
      this.$forceUpdate()
    },
    addToFinal() {
      this.$emit('addToFinal')
    },
  },
  computed: {
    hasAdditional() {
      let show = false
      this.item.attributes.forEach(attr => {
        if (attr['@type'] != 'qty') show = true
      })
      return show
    },
    disabled() {
      return this.$store.roomBreakfastAddingToFinal
    },
  },
}
</script>

<style lang="scss">
.room-breakfast-item-buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
  gap: 15px;

  &.carousel {
    > .controls {
      justify-content: flex-start;
    }
  }

  .buy {
    display: flex;
    $button-size: 32px;
    height: $button-size;

    > * {
      margin-right: 15px;
    }

    .decrease {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #c0c0c0;
      border-radius: 100%;
      width: $button-size;
      * {
        color: white;
      }

      transition: 0.2s all;
      &:hover {
        opacity: 0.75;
        cursor: pointer;
      }
    }
    .current {
      vertical-align: middle;
      position: relative;
      width: 15px;

      color: #47525e;
      font-family: Roboto;
      font-size: 25px;
      line-height: 32.5px;
      font-weight: 700;
    }

    .increase {
      &.canBuy {
        cursor: pointer;
        transition: 0.2s all;
        &:hover {
          opacity: 0.75;
        }
      }

      * {
        color: white;
      }

      &.showPrice {
        .price .value {
          display: block;
        }
      }

      .price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--secondary-color);
        border-radius: 30px;
        height: $button-size + 1px;

        .icon {
          > .md-icon {
            background-color: var(--primary-color);
            border-radius: 100%;
            width: $button-size;
            height: $button-size;
          }
        }

        .value {
          display: none;
          font-weight: 700;
          width: max-content;
          padding: 0 15px;
          color: var(--overlay-text-color);
          font-size: 15px;

          .currency {
            color: var(--overlay-text-color);
          }
        }
      }
    }
  }

  > .additional {
    width: 100%;

    > .select {
      font-weight: 450;
      font-size: 15px;
      cursor: pointer;
      border-bottom: solid 1px #ccc;
      display: block;
      width: fit-content;
    }
  }

  > .add {
    width: 100%;
    padding: 7px 0px;
    color: white;
    background-color: #60a844;
    border-radius: 360px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &.hasAdditional {
      margin-top: 0;
      width: 100%;
    }

    opacity: 1;
    transition: opacity 0.5s;
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: initial;
    }
  }

  > .controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
