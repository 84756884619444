<template>
  <div
    v-if="items.length >= 1"
    class="common-food-item-carousel"
    :class="{ disabled }"
  >
    <div
      ref="items"
      class="items"
      @touchstart="onStartDrag"
      @touchend="onStopDrag"
    >
      <Item
        @touchmove.native="onDrag($event, scrollKey == activeScrollKey)"
        v-for="scrollKey in visibleScrollKeys"
        :key="scrollKey"
        :item="getItemByScrollKey(scrollKey)"
        :scrollKey="scrollKey"
        :activeScrollKey="activeScrollKey"
        :xPosOffset="!draggingDown ? xPosOffset : 0"
        v-show="!draggingDown || scrollKey != activeScrollKey"
        @click.native="activeScrollKey = scrollKey"
        :class="{
          active: scrollKey == activeScrollKey,
        }"
      />

      <Item
        v-if="animationItem"
        :item="animationItem"
        :scrollKey="0"
        :activeScrollKey="0"
        :class="{ active: true }"
        ref="animationItem"
      />
    </div>
  </div>
</template>

<script>
import Item from '@/components/Services/CommonCart/Item'
import { arrayMoveMutable } from 'array-move'
export default {
  props: ['items', 'hidden', 'disabled'],
  components: {
    Item,
  },
  data() {
    return {
      activeScrollKey: 0,
      eachSideItemCount: 3,
      xPosOffset: 0,
      yPosOffset: 0,
      dragging: false,
      draggingActiveItem: false,
      startDragScreenPosX: 0,
      startDragScreenPosY: 0,
      animationItem: null,
      animationItemRect: null,
    }
  },
  model: {
    prop: 'hidden',
    event: 'blur',
  },
  events: {
    'add-to-order'(orderItem, { calledFromEvent }) {
      if (!calledFromEvent) this.animateItem(orderItem)
    },
  },
  methods: {
    getItemByScrollKey(scrollKey = this.activeScrollKey) {
      return this.items.at(scrollKey % this.items.length)
    },
    moveActiveItemFurther() {
      const activeItemIndex = this.items.findIndex(
        item => item.id == this.getItemByScrollKey().id,
      )
      const newIndex =
        activeItemIndex + this.eachSideItemCount + this.activeScrollKey

      this.activeScrollKey++
      arrayMoveMutable(this.items, activeItemIndex, newIndex)
      this.$nextTick(() => {
        this.activeScrollKey--
      })
    },
    async animateItem(animationItem) {
      this.animationItem = animationItem

      const el = await this.$waitFor(() => this.$refs.animationItem?.$el)
      this.animationItemRect = el.getBoundingClientRect()
      const duration = 1000

      this.resetAnimItem(el)

      setTimeout(() => {
        this.moveActiveItemFurther()
        this.moveAnimItemToBottomRight(el, duration)

        setTimeout(() => {
          this.animationItem = null
          this.animationItemRect = null
        }, duration)
      }, 50)
    },
    addToOrder() {
      this.$events.emit('want-add-to-order')
    },
    resetAnimItem(el) {
      const rect = this.animationItemRect
      el.style.position = 'fixed'
      el.style.transition = ''
      el.style.opacity = '1'
      el.style.transform = 'scale(1)'
      el.style.top = rect.y + 'px'
      el.style.left = rect.x + 'px'
      el.style.width = rect.width + 'px'
      el.style.height = rect.height + 'px'
    },
    moveAnimItemToBottomRight(el, duration = 1000) {
      const rect = this.animationItemRect
      el.style.transition = `${duration}ms all`
      el.style.opacity = '0'
      el.style.transform = 'scale(.15)'
      el.style.top = window.innerHeight - rect.height / 2 + 'px'
      el.style.left = window.innerWidth - rect.width / 2 - 50 + 'px'
    },
    moveAnimItemToCursor(el, cursorX, cursorY) {
      const rect = this.animationItemRect
      el.style.top = cursorY - rect.height / 2 + 'px'
      el.style.left = cursorX - rect.width / 2 + 'px'
    },
    scrollNext() {
      this.activeScrollKey++
    },
    scrollPrev() {
      this.activeScrollKey--
    },
    onStartDrag(e) {
      if (this.animationItem) return
      this.startDragScreenPosX = e.touches[0].clientX
      this.startDragScreenPosY = e.touches[0].clientY
      this.dragging = true
    },
    async onDrag(e, draggingActiveItem) {
      if (!this.dragging) return
      this.draggingActiveItem = draggingActiveItem
      e.preventDefault()
      this.xPosOffset = e.touches[0].clientX - this.startDragScreenPosX
      this.yPosOffset = e.touches[0].clientY - this.startDragScreenPosY
      if (Math.abs(this.xPosOffset) > 100 && !this.draggingDown) {
        this.xPosOffset > 0 ? this.scrollPrev() : this.scrollNext()
        this.onStopDrag()
      }
      if (this.draggingDown) {
        if (!this.animationItem) {
          this.animationItem = this.getItemByScrollKey()
          const el = await this.$waitFor(() => this.$refs.animationItem?.$el)
          this.animationItemRect = el.getBoundingClientRect()
          this.resetAnimItem(el)
        }
        const el = this.$refs.animationItem.$el
        if (!this.animationItemRect) return
        const rect = this.animationItemRect
        const { clientX, clientY } = e.touches[0]
        this.moveAnimItemToCursor(el, clientX, clientY)

        const xTriggerRadius = 300
        const yTriggerRadius = 240
        const closeX = Math.abs(window.innerWidth - clientX) <= xTriggerRadius
        const closeY = Math.abs(window.innerHeight - clientY) <= yTriggerRadius
        if (closeX && closeY) {
          this.onStopDrag()
          this.addToOrder()
          this.animationItem = this.getItemByScrollKey()
          this.animationItemRect = rect
          this.moveActiveItemFurther()
          const duration = 300
          this.moveAnimItemToBottomRight(el, duration)
          setTimeout(() => {
            this.animationItem = null
            this.animationItemRect = null
          }, duration)
        }
      } else {
        this.animationItem = null
        this.animationItemRect = null
      }
    },
    onStopDrag() {
      if (!this.dragging) return
      this.animationItem = null
      this.animationItemRect = null
      this.dragging = false
      this.draggingActiveItem = false
      this.xPosOffset = 0
      this.yPosOffset = 0
    },
  },
  computed: {
    visibleScrollKeys() {
      return [...Array(this.eachSideItemCount * 2 + 1).keys()].map(
        i => this.activeScrollKey + (i - this.eachSideItemCount),
      )
    },
    draggingDown() {
      return !this.disabled && this.draggingActiveItem && this.yPosOffset >= 45
    },
  },
  watch: {
    activeScrollKey: {
      immediate: true,
      handler() {
        this.$emit('blur', this.getItemByScrollKey().id)
      },
    },
    hidden(itemId) {
      if (itemId == null) itemId = this.items[0].id
      if (this.getItemByScrollKey().id != itemId)
        this.activeScrollKey = this.items.findIndex(item => item.id == itemId)
    },
  },
}
</script>

<style lang="scss">
.common-food-item-carousel {
  width: 100%;
  margin: 15px 0;

  &.disabled {
    opacity: 0.5;
  }

  .items {
    height: 270px;
    position: relative;
    overflow-x: hidden;
    white-space: nowrap;
    padding: 20px 5px;
    touch-action: pan-y;

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-bottom: 10px;
  }
}
</style>
