<template>
  <div class="service-detail-chips">
    <span class="chip" v-for="chip in detail.values" :key="chip.key">
      <b>{{ chip.key }}</b>
      <span>{{ chip.value }}</span>
    </span>
  </div>
</template>

<script>
export default {
  props: ['detail'],
}
</script>

<style lang="scss">
.service-detail-chips {
  text-align: center;

  .chip {
    display: inline-block;
    margin-bottom: 15px;
    margin-right: 15px;
    max-width: 45%;
    vertical-align: middle;

    > * {
      display: block;
    }
  }
  .chip:last-of-type {
    margin-right: 0;
  }
}
</style>
