<template>
  <div
    class="common-food-item"
    v-if="viewportWidth > 0"
    :style="{
      width: itemWidth + 'px',
      minWidth: itemWidth + 'px',
      maxWidth: itemWidth + 'px',
      left: xPos + 'px',
      transition: animated ? '0.25s all' : 'none',
    }"
  >
    <div class="item-card">
      <div
        class="avatar"
        v-if="hasImage"
        :style="{
          backgroundImage: `url(${item.avatar})`,
        }"
      />

      <div class="info">
        <div class="title">{{ item.title }}</div>
        <div class="description">{{ $stripHtml(item.description) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item', 'scrollKey', 'activeScrollKey', 'xPosOffset'],
  data() {
    return {
      viewportWidth: 0,
    }
  },
  mounted() {
    this.calculateViewportWidth()
  },
  methods: {
    async calculateViewportWidth() {
      this.$waitFor(() => this.$el)
      this.viewportWidth = this.$el.parentElement.clientWidth
    },
    _resize() {
      this.calculateViewportWidth()
    },
  },
  computed: {
    animated() {
      return Math.abs(this.scrollKey - this.activeScrollKey) <= 2
    },
    xPos() {
      return (
        (this.scrollKey - this.activeScrollKey) * this.itemWidth +
        (this.viewportWidth - this.itemWidth) / 2 +
        (this.xPosOffset ?? 0)
      )
    },
    itemWidth() {
      return this.viewportWidth / 3
    },
    hasImage() {
      return this.item?.avatar && !this.item?.avatar.endsWith('/')
    },
  },
  watch: {
    '$store.routerWidth'() {
      this.calculateViewportWidth()
    },
  },
}
</script>

<style lang="scss">
.common-food-item {
  position: absolute;
  height: calc(100% - 65px);
  margin: 15px 0;
  padding: 0 5px;
  white-space: normal;
  cursor: pointer;
  opacity: 0.6;
  filter: blur(1px);
  display: flex;

  &.active {
    filter: none;
    z-index: 2;
    opacity: 1;
    cursor: default;
    transform: scale(1.2);
    padding: 0;
  }

  > .item-card {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0px 1px 4px 0px rgba(24, 27, 29, 0.3);

    .avatar {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      min-height: 110px;

      background-size: cover;
      background-position: center;
    }
    .info {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px;
      text-align: center;

      .title {
        color: #47525e;
        font-family: Roboto;
        font-size: 12px;
        line-height: 1.1;
        margin-bottom: 5px;
        font-weight: 500;
        word-wrap: break-word;
      }
      .description {
        color: #8492a6;
        font-family: Roboto;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}
</style>
