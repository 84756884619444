<template>
  <Loading v-if="!offer && preloadOffer" />
  <div id="common" v-else>
    <TopMenu
      :title="service.title"
      :avatar="service.avatar"
      :description="service.description"
      :customBack="
        () => {
          $router.push('/hotel/' + service.propertyId)
        }
      "
    />

    <div class="content">
      <div class="service-details" style="margin-top: 10px">
        <ServiceDetail
          v-for="detail in service.serviceDetails"
          :key="detail.key"
          :detail="detail"
          :offer="offer"
        />
      </div>

      <NextButton
        v-if="canMakeOrder"
        :click="makeOrder"
        :floating="true"
        floatingIcon="arrow_forward"
        :label="
          [
            '84580008-f713-4bf3-bb93-5b00213e459c_d790d9b5-5e6c-4e32-9092-b848bc8aa06e',
            '52d0b758-e281-4b5b-89cc-0c10d332ccb6_d790d9b5-5e6c-4e32-9092-b848bc8aa06e',
          ].includes(id)
            ? 'Голосовать'
            : undefined
        "
      />
      <LegalInfo :text="offer.legalInfo" v-if="offer" />
    </div>
  </div>
</template>

<script>
import ServiceDetail from '@/components/ServiceDetail'
import TopMenu from '@/components/TopMenu'
import NextButton from '@/components/NextButton'
import LegalInfo from '@/components/LegalInfo'
import getInputPatternByType from '@/lib/getInputPatternByType'

export default {
  components: { ServiceDetail, TopMenu, NextButton, LegalInfo },
  props: ['id', 'service'],
  data() {
    return {
      offer: null,
    }
  },
  async created() {
    if (this.preloadOffer) await this.fetchOffer()
  },
  methods: {
    async fetchOffer() {
      const resp = await this.$api.get(`offers/${this.preloadOffer.id}`)
      this.offer = resp.data.data
    },
    makeOrder() {
      let error = null

      for (const attr of this.offer.attributes) {
        const type = attr['@type']
        const value = this.$store.attributesSaves[attr.id].value

        if (type == 'privacy_policy' && value != true)
          error = this.$t('settings.setCheck', [attr.title])

        if (type == 'input' && attr.properties?.INPUT_TYPE) {
          const attrInputType =
            attr.properties.INPUT_TYPE.toLowerCase() || 'default'
          const pattern = getInputPatternByType(attrInputType)
          const passedPattern = new RegExp(pattern).test(value)
          const canSkip = !attr.mandatory && value.length == 0
          if (!passedPattern && !canSkip)
            error = this.$t('inputPatternErrors.' + attrInputType)
        }
      }

      if (error) {
        this.$events.emit('show-alert', this.$t('errors.error'), error)
        return
      }

      const save = this.$store.offerSaves[this.offer.id]

      const orderItemRequests = [
        this.offer,
        save.selectedChild,
        save.selectedSubChild,
      ]
        .filter(offer => offer)
        .map(offer => ({
          action: 'ADD',
          offerId: offer.id,
          attributeValues: this.getAttributeValuesFromOffer(offer),
        }))

      this.$api
        .post(`user/orders`, {
          forceValidation: true,
          action: 'CREATE',
          serviceId: this.id,
          availablePaymentMethods: true,
          orderItemRequests,
        })
        .then(res => {
          const type = this.service.type == 'BOOKING' ? 'booking' : 'order'
          this.$router.push(`/${type}?orderId=${res.data.data.orderId}`)
        })
        .catch(err => {
          if (!this.$store.isLoginError(err)) {
            this.$events.emit(
              'show-alert',
              this.$t('errors.error'),
              err.response.data.message,
            )
          } else this.$events.once('authorized', this.makeOrder)
        })
    },
    getAttributeValuesFromOffer(offer) {
      return offer.attributes.map(attr => ({
        ...attr,
        value: this.$store.attributesSaves[attr.id].getValue(),
      }))
    },
  },
  computed: {
    canMakeOrder() {
      return !!this.offer?.hasPaymentMethods
    },
    preloadOffer() {
      return this.service.serviceDetails.find(
        detail => detail['@type'] == 'OFFER',
      )?.offer
    },
  },
}
</script>

<style lang="scss">
#common {
  background-color: white;

  > .content {
    position: absolute;
    margin-top: -17px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: white;
    width: 100%;
  }
}
</style>
