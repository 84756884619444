<template>
  <div id="offer">
    <div
      class="avatar"
      :style="{ backgroundImage: `url(${offer.avatar})` }"
    ></div>
    <div class="attributes">
      <div
        class="sub-selector"
        v-if="isChildOffersAvailable && !useItemCarousel"
      >
        <md-field>
          <label for="select">{{ $stripHtml(offer.description) }}</label>
          <md-select v-model="$save.selectedChildId" name="select">
            <md-option
              v-for="child in availableChildOffers"
              :key="child.id"
              :value="child.id"
              >{{ child.title }}</md-option
            >
          </md-select>
        </md-field>
      </div>
      <div
        class="sub-selector-carousel without-padding"
        v-if="
          useItemCarousel &&
            (isChildOffersAvailable ||
              (offer.childOffers && offer.childOffers.length > 0))
        "
      >
        <ItemCarousel
          v-model="$save.selectedChildId"
          :items="
            isChildOffersAvailable ? availableChildOffers : offer.childOffers
          "
          :disabled="!isChildOffersAvailable"
        />
      </div>

      <div v-if="$save.selectedChild != null" class="sub-attrs">
        <AttributeWrapper
          v-for="attribute in $save.selectedChild.attributes"
          :key="attribute.id"
          :attribute="attribute"
        />
      </div>

      <div
        v-if="$save.selectedChild && $save.selectedChild.childOffers"
        class="sub-sub-selector"
      >
        <md-field>
          <label for="select2">{{
            $stripHtml($save.selectedChild.description)
          }}</label>

          <md-select v-model="$save.selectedSubChildId" name="select2">
            <md-option
              v-for="child in $save.selectedChild.childOffers"
              :key="child.id"
              :value="child.id"
              >{{ child.title }}</md-option
            >
          </md-select>
        </md-field>
      </div>
      <div
        class="description"
        v-html-reactive-links
        v-html="
          $save.selectedSubChild != null &&
          $save.selectedSubChild.description != null
            ? getFormattedDescription($save.selectedSubChild.description)
            : ''
        "
      ></div>

      <div class="root-attributes" v-if="!hideRootAttributes">
        <AttributeWrapper
          v-for="attribute in offer.attributes"
          :key="attribute.id"
          :attribute="attribute"
          :offer="offer"
          :subOffer="$save.selectedChild"
          :subSubOffer="$save.selectedSubChild"
        />
      </div>

      <div v-if="$save.selectedSubChildId !== null" class="sub-sub-attrs">
        <AttributeWrapper
          v-for="attribute in $save.selectedSubChild.attributes"
          :key="attribute.id"
          :attribute="attribute"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import AttributeWrapper from '@/components/Attributes/AttributeWrapper'
import ItemCarousel from '@/components/Services/CommonCart/ItemCarousel'

export default {
  props: ['offer', 'useItemCarousel', 'hideRootAttributes', 'order'],
  components: { AttributeWrapper, ItemCarousel },
  created() {
    this.initSave()
    this.initWatchers()
    this.resetSelectedChild()
  },
  destroyed() {
    if (this.destroyChildWatcher) this.destroyChildWatcher()
    if (this.destroySubChildWatcher) this.destroySubChildWatcher()
  },
  methods: {
    initSave() {
      const offerId = this.offer.id
      const saves = this.$store.offerSaves

      if (!saves[offerId]) {
        saves[offerId] = {
          selectedChildId: null,
          selectedChild: null,
          selectedSubChildId: null,
          selectedSubChild: null,
        }
      }

      this.$save = Vue.observable(saves[offerId])
    },
    initWatchers() {
      this.destroyChildWatcher = this.$watch(
        () => this.$save.selectedChildId,
        this.watchSelectedChildId,
      )
      this.destroySubChildWatcher = this.$watch(
        () => this.$save.selectedSubChildId,
        this.watchSelectedSubChildId,
      )
    },
    resetSelectedChild() {
      this.$save.selectedChildId =
        this.availableChildOffers?.length > 0
          ? this.availableChildOffers[0].id
          : null

      let queryOfferId = this.$route.query.childOfferId
      if (queryOfferId) this.$save.selectedChildId = queryOfferId
    },
    watchSelectedChildId(value) {
      this.offer.childOffers.forEach(child => {
        if (child.id == value) this.$save.selectedChild = child
      })
      if (this.$save.selectedChild.childOffers)
        this.$save.selectedSubChildId = this.$save.selectedChild.childOffers[0].id
      else this.$save.selectedSubChildId = null
    },
    watchSelectedSubChildId(value) {
      this.$save.selectedSubChild = null
      if (this.$save.selectedChild.childOffers) {
        for (const child of this.$save.selectedChild.childOffers) {
          if (child.id == value) this.$save.selectedSubChild = child
        }
      }
    },
    getFormattedDescription(text) {
      return text
        .replace(/\r\n/g, '<br>')
        .replace(/\r\n/g, '<br>')
        .replace(/\n/g, '<br>')
        .replace(/\r/g, '<br>')
    },
  },
  computed: {
    availableChildOffers() {
      if (!this.order) return this.offer.childOffers
      const orderIds = this.order.orderItem.orderItems.map(item => item.offerId)
      return this.offer.childOffers.filter(
        offer => offer.reusable || !orderIds.includes(offer.id.split('_')[0]),
      )
    },
    isChildOffersAvailable() {
      return (
        Array.isArray(this.availableChildOffers) &&
        this.availableChildOffers.length > 0
      )
    },
  },
}
</script>

<style lang="scss">
#offer {
  > .avatar {
    background-size: cover;
    background-position: center;

    width: 100%;
    max-height: 215px;
    margin-bottom: 15px;
  }
  > .attributes {
    > *:not(.without-padding) {
      padding: 0 15px;
    }

    > .description {
      color: #7a7a7a;
      line-height: 20px;
    }

    .privacy-policy-label {
      width: 100%;
    }
  }
}
</style>
