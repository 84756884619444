<template>
  <div
    class="room-breakfast-item-carousel"
    :class="{ collapsed: allItemsAlreadyInCart }"
  >
    <div v-if="description" class="description">
      {{ $stripHtml(description) }}
    </div>
    <div class="content">
      <div ref="items" class="items" @scroll="updateCategoriesScroll">
        <RoomBreakfastItem
          v-for="item in items"
          :carousel="true"
          :key="item.id + item.active"
          :item="item"
          :order="order"
          :disableBuying="disableBuying"
          @increaseItem="increaseItem"
          @decreaseItem="decreaseItem"
          @addToFinal="addToFinal"
          @updateCategoriesScroll="updateCategoriesScroll"
        />
      </div>
      <div
        class="arrow left"
        @click="scrollLeft"
        :class="{ show: scrollLeftShow && items.length > 1 }"
      >
        <md-icon>chevron_left</md-icon>
      </div>
      <div
        class="arrow right"
        @click="scrollRight"
        :class="{ show: scrollRightShow && items.length > 1 }"
      >
        <md-icon>chevron_right</md-icon>
      </div>
    </div>
  </div>
</template>

<script>
import RoomBreakfastItem from '@/components/Services/RoomBreakfast/Item'

export default {
  components: { RoomBreakfastItem },
  props: ['items', 'description', 'order', 'disableBuying'],
  data() {
    return {
      cardStep: 350,
      scrollRightShow: true,
      scrollLeftShow: false,
    }
  },
  mounted() {
    if (this.$refs.items?.children.length > 1)
      this.cardStep = this.$refs.items.children[1].offsetLeft
  },
  methods: {
    scrollLeft() {
      let cards = this.$refs.items
      let goal = cards.scrollLeft - this.cardStep

      let steps = []
      Array.from(cards.children).forEach(card => {
        steps.push(card.offsetLeft)
      })

      let closest = steps.reduce(function(prev, curr) {
        return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev
      })

      if (closest == steps[0]) closest = 0

      window.smoothScroll({
        scrollingElement: cards,
        xPos: closest - 75,
      })

      this.updateCategoriesScroll()
    },
    scrollRight() {
      let cards = this.$refs.items
      let goal = cards.scrollLeft + this.cardStep

      let steps = []
      Array.from(cards.children).forEach(card => {
        steps.push(card.offsetLeft)
      })

      let closest = steps.reduce(function(prev, curr) {
        return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev
      })

      window.smoothScroll({
        scrollingElement: cards,
        xPos: closest - 75,
      })

      this.updateCategoriesScroll()
    },
    updateCategoriesScroll() {
      let el = this.$refs.items
      if (!el) return
      let maxScrollLeft = el.scrollWidth - el.clientWidth

      this.scrollLeftShow = el.scrollLeft > 10
      this.scrollRightShow = maxScrollLeft - el.scrollLeft > 10
    },
    increaseItem(...args) {
      this.$emit('increaseItem', ...args)
    },
    decreaseItem(...args) {
      this.$emit('decreaseItem', ...args)
    },
    addToFinal(...args) {
      this.$emit('addToFinal', ...args)
    },
  },
  computed: {
    allItemsAlreadyInCart() {
      if (!this.order) return false

      const cartIds = this.order.orderItem.orderItems.map(item => item.offerId)
      const containsItemThatStilNotOrdered = !!this.items.find(
        item => !cartIds.includes(item.id.split('_')[0]),
      )
      const containsReusableItem = !!this.items.find(item => item.reusable)

      return !containsItemThatStilNotOrdered && !containsReusableItem
    },
  },
}
</script>

<style lang="scss">
.room-breakfast-item-carousel {
  margin-top: 25px;

  transition: max-height 0.5s, margin-top 0.5s, overflow 0.5s;
  max-height: 350px;

  &.collapsed {
    margin-top: 0;
    max-height: 0px;
    overflow: hidden;
  }

  > .description {
    margin-left: 10px;
  }

  > .content {
    position: relative;
    padding-right: 15px;
    width: 100%;

    > .items {
      display: flex;

      position: relative;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
      padding: 0 10px 0 10px;

      > *:last-child {
        margin-right: 0;
      }
    }

    > .arrow {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 75px;
      z-index: 1;
      transition: 0.3s all;
      opacity: 0;
      z-index: 1;
      cursor: pointer;
      pointer-events: none;

      &.show {
        opacity: 1;
        pointer-events: initial;
      }

      .md-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 48px !important;
      }

      &.left {
        left: 0;
        background: linear-gradient(
          to right,
          white 30%,
          rgba(255, 255, 255, 0)
        );

        .md-icon {
          left: 15px;
        }
      }
      &.right {
        right: 0;
        background: linear-gradient(to left, white 30%, rgba(255, 255, 255, 0));

        .md-icon {
          right: 15px;
        }
      }
    }
  }
}
</style>
