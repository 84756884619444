<template>
  <div class="room-breakfast-categories">
    <div ref="marker"></div>
    <div
      ref="dummy"
      v-if="categoriesFixed"
      :style="{ height: categoriesHeight + 'px' }"
    ></div>
    <div
      ref="categories"
      class="categories"
      :class="{ fixed: categoriesFixed }"
      :style="{ width: fixedWidth + 'px', top: topMenuHeight + 'px' }"
      v-show="categories.length > 1"
    >
      <div class="arrows">
        <div
          class="arrow left"
          :class="{ show: scrollLeftShow }"
          @click="scrollLeft"
        >
          <md-icon>chevron_left</md-icon>
        </div>
        <div
          class="arrow right"
          :class="{ show: scrollRightShow }"
          @click="scrollRight"
          :style="{ left: fixedWidth - 75 + 'px' }"
        >
          <md-icon>chevron_right</md-icon>
        </div>
      </div>

      <div
        class="category"
        v-for="(category, key) in categories"
        v-show="category != null"
        :key="key"
        :class="{ active: category.id == activeCategory.id }"
        @click="setActiveCategoryById(category.id)"
      >
        <div
          class="avatar"
          :style="{
            backgroundImage: `url(${
              hasImage(category.avatar)
                ? category.avatar
                : require('@/assets/FoodStub2.png')
            })`,
          }"
          :class="hasImage(category.avatar) ? '' : 'hue-offset'"
        >
          <div class="avatar-border"></div>
        </div>
        <span class="title">{{ category.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['categories', 'service', 'activeCategory', 'topMenuHeight'],
  data() {
    return {
      scrollLeftShow: false,
      scrollRightShow: true,

      categoriesFixed: false,
      categoriesHeight: 0,

      fixedWidth: this.$full ? this.routerWidth : window.innerWidth,
      restoredQueryCategory: false,
    }
  },
  methods: {
    hasImage(url) {
      return url && !url.endsWith('/')
    },
    initCategoriesScroll() {
      this.$refs.categories.addEventListener(
        'scroll',
        this.updateCategoriesScroll
      )
    },
    updateCategoriesScroll() {
      let el = this.$refs.categories
      if (!el) return
      let maxScrollLeft = el.scrollWidth - el.clientWidth

      this.scrollLeftShow = el.scrollLeft > 10
      this.scrollRightShow = maxScrollLeft - el.scrollLeft > 10
    },
    updateCategoriesFixed() {
      if (!this.$refs.marker) return
      const topmenuHeight = 51
      this.categoriesHeight = this.$refs.categories.getBoundingClientRect().height
      this.categoriesFixed =
        this.$refs.marker.getBoundingClientRect().bottom - topmenuHeight <= 0
    },
    scrollLeft() {
      window.smoothScroll({
        scrollingElement: this.$refs.categories,
        xPos: this.$refs.categories.scrollLeft - 120,
      })
    },
    scrollRight() {
      window.smoothScroll({
        scrollingElement: this.$refs.categories,
        xPos: this.$refs.categories.scrollLeft + 120,
      })
    },
    updateFixedWidth() {
      this.fixedWidth = this.$full ? this.routerWidth : window.innerWidth
    },
    restoreQueryCategory() {
      let categoryId = this.$sessionStorage.roomBreakfastCategoryIdSaves[
        this.service.id
      ]
      if (categoryId) {
        for (let category of this.categories) {
          if (category.id == categoryId) {
            this.setActiveCategoryById(categoryId)
            this.$nextTick(this.scrollToActiveCategory)
            break
          }
        }
        this.restoredQueryCategory = true
      } else this.restoredQueryCategory = true
    },
    scrollToActiveCategory() {
      let categories = this.$refs.categories
      let left = 0

      for (let category of categories.children) {
        if (category.classList.contains('active'))
          left = category.offsetLeft - category.offsetWidth
      }

      window.smoothScroll({
        scrollingElement: categories,
        xPos: left,
      })
    },
    setActiveCategoryById(categoryId) {
      this.$emit('setActiveCategoryById', categoryId)
    },
    _scroll() {
      this.updateCategoriesFixed()
    },
    _resize() {
      this.updateFixedWidth()
    },
  },
  mounted() {
    this.initCategoriesScroll()

    let interval = setInterval(() => {
      if (this.routerWidth != 0) {
        this.updateFixedWidth()
        clearInterval(interval)
      }
    }, 1)

    let interval2 = setInterval(() => {
      if (this.$refs.categories) {
        this.updateCategoriesScroll()
        clearInterval(interval2)
      }
    }, 500)
  },
  computed: {
    routerWidth() {
      return this.$store.routerWidth
    },
  },
  watch: {
    routerWidth() {
      this.updateFixedWidth()
    },
    activeCategory(category) {
      if (!this.restoredQueryCategory) return
      this.$sessionStorage.roomBreakfastCategoryIdSaves[this.service.id] =
        category.id
    },
  },
}
</script>

<style lang="scss">
.room-breakfast-categories {
  .categories {
    $category-width: 95px;
    height: $category-width + 20px;
    background-color: white;

    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    box-shadow: 0 5px 15px 0 transparent;
    transition: 0.2s all;

    &.fixed {
      position: fixed;
      z-index: 15;
      box-shadow: 0 5px 15px 0 #f7f2f2;

      .arrows {
        position: fixed;
        z-index: 16;
      }
    }

    .arrows {
      position: absolute;
      width: 100%;
      height: $category-width + 20px;
      pointer-events: none;

      .arrow {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 75px;
        transition: 0.3s all;
        opacity: 0;
        z-index: 1;
        cursor: pointer;
        pointer-events: none;

        &.show {
          opacity: 1;
          pointer-events: initial;
        }

        .md-icon {
          position: absolute;
          top: 40px;
          transform: translateY(-50%);
          font-size: 48px !important;
        }

        &.left {
          left: 0;
          background: linear-gradient(
            to right,
            white 30%,
            rgba(255, 255, 255, 0)
          );

          .md-icon {
            left: 15px;
          }
        }
        &.right {
          background: linear-gradient(
            to left,
            white 30%,
            rgba(255, 255, 255, 0)
          );

          .md-icon {
            right: 15px;
          }
        }
      }
    }

    .category {
      position: relative;
      display: inline-block;
      width: $category-width;
      height: 100%;
      background-color: transparent;
      transition: 0.2s all;

      &:hover {
        cursor: pointer;
        filter: brightness(1.1);
      }
      &.active {
        .title {
          color: #4c5666;
        }
        .avatar {
          .avatar-border {
            border-color: rgb(88, 88, 88);
          }
        }
      }

      .avatar {
        background-position: center;
        background-size: cover;
        margin-top: 5px;
        margin-left: 50%;
        transform: translateX(-50%);
        width: ($category-width - 30px);
        height: ($category-width - 30px);
        border-radius: 360px;

        .avatar-border {
          $gap: 7px;
          transform: translate(-$gap/2, -$gap/2);
          width: ($category-width - 30px) + $gap;
          height: ($category-width - 30px) + $gap;
          border-radius: 360px;
          border: solid transparent 2px;
          transition: 0.2s all;
        }
      }
      .title {
        margin-top: 3px;
        position: absolute;
        white-space: initial;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        word-wrap: break-word;

        font-size: 15px;
        text-align: center;
        color: #8492a6;

        transition: 0.2s all;
      }
    }
  }
}
</style>
